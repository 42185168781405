export default function InstaIcon() {
  return (
    <svg
      width="29"
      height="27"
      viewBox="0 0 58 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.342 10.4995H13.658C12.3449 10.4995 11.2769 11.4939 11.2769 12.7163V41.2841C11.2769 42.5065 12.3449 43.5011 13.658 43.5011H44.3415C45.6544 43.5011 46.7227 42.5065 46.7227 41.2841L46.7229 12.7163C46.7229 11.4939 45.6546 10.4995 44.3417 10.4995H44.342ZM44.6071 41.284C44.6071 41.4178 44.4855 41.5306 44.342 41.5306H13.658C13.5144 41.5306 13.393 41.4178 13.393 41.284V12.7162C13.393 12.5824 13.5146 12.4696 13.658 12.4696H44.3415C44.4852 12.4696 44.6066 12.5824 44.6066 12.7162V41.284H44.6071ZM29.0002 17.7157C23.5013 17.7157 19.0281 21.8809 19.0281 27.0001C19.0281 32.1198 23.5016 36.2845 29.0002 36.2845C34.4986 36.2845 38.9724 32.1195 38.9724 27.0001C38.9724 21.8809 34.4987 17.7157 29.0002 17.7157ZM29.0002 33.9994C24.8551 33.9994 21.4825 30.8596 21.4825 27.0001C21.4825 23.1406 24.855 20.0008 29.0002 20.0008C33.1454 20.0008 36.518 23.1406 36.518 27.0001C36.518 30.8596 33.1456 33.9994 29.0002 33.9994ZM39.8578 19.441C38.4699 19.441 37.3449 18.3935 37.3449 17.1012C37.3449 15.809 38.4699 14.7616 39.8578 14.7616C41.2457 14.7616 42.3706 15.809 42.3706 17.1012C42.3706 18.3934 41.2457 19.441 39.8578 19.441Z"
        fill="black"
      />
    </svg>
  );
}
