export default function Heart() {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4527 4.34679C15.4375 4.13257 15.409 3.91841 15.3691 3.70757C15.2703 3.19249 15.1658 2.67739 14.8295 2.23367C14.4362 1.71349 13.8282 1.58256 13.2126 1.88349C13.0815 1.94807 12.958 2.02969 12.8459 2.11807C12.3044 2.53799 11.9187 3.06834 11.5558 3.61237C10.7426 4.83297 10.0909 6.12497 9.46961 7.42887C9.44301 7.48665 9.44301 7.58526 9.35179 7.58189C9.28146 7.57851 9.27961 7.4867 9.25487 7.4323C8.89385 6.60271 8.49487 5.7884 8.0028 5.0132C7.69312 4.52529 7.34918 4.0595 6.86848 3.69061C6.52077 3.422 6.12938 3.24691 5.6487 3.29793C5.06917 3.36085 4.6854 3.68551 4.42323 4.11051C3.45425 5.68301 3.2528 7.34731 3.76015 9.07792C4.15536 10.4243 4.90206 11.6126 5.85585 12.6972C6.69755 13.6543 7.60385 14.5553 8.77045 15.2166C8.93002 15.3067 9.04023 15.2914 9.17896 15.2081C9.7319 14.8715 10.2259 14.4737 10.7046 14.0606C12.0213 12.925 13.148 11.6551 14.0182 10.2118C14.9473 8.66989 15.4907 7.03279 15.4983 5.16789C15.5173 4.95876 15.4737 4.65281 15.4528 4.34678L15.4527 4.34679Z"
        fill="black"
      />
    </svg>
  );
}
