export default function Logo({ size }: { size: number }) {
    const width = 8 * size;
    const height = 10 * size;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5527 53.1766C38.6127 49.8865 32.6827 47.4966 29.6727 48.2366C28.2127 46.9166 26.2727 45.1866 24.1427 43.0466C26.3727 40.8165 28.5727 38.3566 30.6827 35.8466C34.6227 36.4666 44.5127 30.4868 47.1827 19.4166C47.2927 18.9666 46.1327 18.1665 45.9127 18.6465C43.1727 24.6267 38.8527 31.2966 36.0927 29.0665C39.5227 24.5665 42.4227 20.4666 44.3527 17.9066C45.2027 16.7765 43.8327 15.9465 43.1127 16.8366C40.9127 19.5666 37.8227 23.5165 34.3327 27.7565C31.9127 25.5265 36.4127 20.0766 41.7827 15.5665C42.2627 15.1665 41.1227 14.5666 40.6227 14.8466C31.8227 19.7967 27.8227 30.1165 28.8827 34.1566C26.7127 36.6166 24.4927 39.0165 22.3227 41.1666C20.5827 39.2965 18.7827 37.1666 17.0827 34.7866C14.4027 31.0365 12.1827 26.4666 12.1827 26.4666C12.0627 26.2166 11.9527 25.9966 11.8627 25.7966C12.0727 25.5666 12.2727 25.3366 12.4927 25.1066C16.5427 20.7665 28.8828 4.27695 18.8828 0.276946C17.3828 -0.222656 15.6398 0.106703 15.1797 0.106426C6.84969 1.84646 4.42272 15.1564 8.92272 25.9166C8.64272 26.2366 8.40271 26.4965 8.19271 26.7066C-3.56729 38.6268 -0.957292 48.7965 5.38271 51.7666C10.2227 54.0266 16.3827 50.4267 22.4727 44.7266C24.1627 46.3266 25.9627 47.9765 27.9027 49.8665C26.9727 52.8666 30.2327 59.3465 35.0827 62.9066C39.8527 66.4166 45.7727 66.5266 47.1627 63.7866C48.5427 61.0865 46.7527 56.6566 42.5927 53.1865L42.5527 53.1766ZM14.6797 2.27707C14.6796 2.2868 15.6797 1.77693 16.6797 1.77693C17.6797 1.77693 18.6797 2.27707 18.6797 2.27707C24.8697 5.63713 15.5827 17.3564 10.8327 23.5266C6.59271 13.5464 9.12969 4.69711 14.6797 2.27707ZM7.43271 49.4866C2.07271 47.0465 -0.11729 40.0768 9.85271 28.0466C13.1927 34.9767 16.5727 38.9965 20.5127 42.8665C15.2727 47.7566 10.4427 50.8566 7.43271 49.4866Z"
        fill="#231F20"
      />
    </svg>
  );
}
